<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!programId" icon @click="show">
        <v-icon>change_history</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-toolbar class="my-bar">
        <v-toolbar-title>Просмотр изменений</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="view" style="max-height: calc(100vh - 64px)">
        <div class="my-content">
          <v-form ref="form" style="height: 100%">
            <v-simple-table fixed-header dense style="height: 100%">
              <template v-slot:default>
                <thead>
                  <th>№</th>
                  <th>Состояние</th>
                  <th>Дата</th>
                  <th>Пользователь</th>

                  <th>Категория услуги</th>
                  <th>Сумма</th>
                  <th>Категория застрахованного</th>
                  <th>Номер объединения</th>
                  <th v-if="byInsured">
                    В рамках
                  </th>

                </thead>
                <tbody v-for="(item, i) in items" :key="i">
                  <ProgramLimitHistoryItem
                    :item="item"
                    :index="i"
                    :by-insured="byInsured"
                  ></ProgramLimitHistoryItem>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ctx from "@/ctx";
import ProgramLimitHistoryItem from "./ProgramLimitHistoryItem.vue";
import fetch from "../../utils/fetch";

import datetimeLocalFilter from "../../filters/datetimeLocal.filter";

const stateLocalizationDictionary = {
  UPDATE: "Обновлено",
  DELETE: "Удалено",
  CREATE: "Создано"
};

let Item = function(d) {
  let self = this;

  self.date2vm = function(val) {
    if (!val) return;
    return datetimeLocalFilter(val);
  };

  self.date = self.date2vm(d.date);
  self.state = stateLocalizationDictionary[d.state];
  self.userName = d.userName;

  self.additional = d.additional;
  if (!self.additional) {
    self.additional = [];
  }
  self.createdInProgram = d.createdInProgram
};

export default {
  name: "ProgramLimitHistory",
  props: {
    programId: Number,
    insuredId: Number
  },
  data: () => ({
    ctx,
    items: [],
    dialog: false,
    loading: false
  }),
  methods: {
    async show() {
      this.items = [];
      this.loading = true;
      this.dialog = true;
      const res = await fetch.get(
        this.getUrl()
      );

      if (res) res.forEach(r => this.items.push(new Item(r)));
      this.loading = false;


    },
    getUrl() {
      let url = '/api/insurance/programs/get-limit-logs-by-program-and-insured?programId=' + this.programId
      if(this.insuredId !== null) url += '&insuredId=' + this.insuredId
      return url
    }
  },
  computed: {
    byInsured() {
      return this.insuredId !== null;
    }
  },
  mounted() {
    this.items = [];

  },
  components: {
    ProgramLimitHistoryItem
  }
};
</script>
