<template>
  <div id="listWrapper">

    <v-toolbar v-if="!mainView" class="my-bar">
      <v-spacer></v-spacer>
      <ButtonFacade
        icon="cloud_download"
        :handler="exp"
      ></ButtonFacade>
    </v-toolbar>
    <!-- ref="dataTable" -->
    <v-data-table
    
      v-model="selected"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :single-select="true"
      item-key="id"
      show-select
      hide-default-footer
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
      fixed-header
      :item-class="itemColor"
      @click:row="rowClick"
    >

      <template v-if="!mainView" v-slot:item.index="{index}">
        <span>{{index + 1}}</span>
      </template>
      <template v-slot:item.iin="{item}">
        <span v-if="!mainView">{{item.iin}}</span>
      </template>
      <template v-slot:item.startDate="{ item }">
        <span>{{ item.startDate | dateLocalFilter }}</span>
      </template>
      <template v-slot:item.endDate="{ item }">
        <span>{{ item.endDate | dateLocalFilter }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import fetch from "@/utils/fetch";
import js2vm from "@/utils/js2vm";
import ctx from "@/ctx";
import dateIsoFilter from "../../filters/dateIso.filter";
import vm2js from "../../utils/vm2js";
import ButtonFacade from "@/components/ButtonFacade";
import {getCardStatusByInsuredData} from "@/ctx/src/insured";
import insuredCardStatusEnum from "@/utils/staticData/insuredCardStatusEnum";
import moment from "moment";

let Item = function (d) {
  let self = this;

  d.person4List = d.personId;

  self.person4List2vm = function (val) {
    return ctx.src.persons.get(val);
  };

  self.cityName = d.cityName;

  d.parent = d.parentPersonId;

  self.parent2vm = function (val) {
    return !val ? {fullName: undefined} : ctx.src.persons.get(val);
  };

  d.underwriter = d.underwriterId;
  d.organization = d.organizationId;

  self.underwriter2vm = function (val) {
    return ctx.src.org.first(val);
  };
  self.organization2vm = function (val) {
    return ctx.src.org.first(val);
  };

  d.program = d.programId;
  self.program2vm = function (val) {
    return ctx.src.programs.get(val);
  };

  self.startDate2vm = function (val) {
    return val ? dateIsoFilter(val) : dateIsoFilter(new Date());
  };

  self.endDate2vm = function (val) {
    return val ? dateIsoFilter(val) : dateIsoFilter(new Date());
  };

  self.init = function (d, update = false) {
    d.person4List = d.personId;
    d.parent = d.parentPersonId;
    d.underwriter = d.underwriterId;
    d.organization = d.organizationId;
    d.program = d.programId;
    // d.row_num = d.rowNum;
    js2vm(self, d, update);
  };
  self.cardStatus = getCardStatusByInsuredData({
    startDate: d.startDate,
    endDate: d.endDate,
    cardNo: d.cardNo
  })

  self.init(d);
};

export default {
  components: {ButtonFacade},
  props: {
    contractId: Number,
    vm: Object,
    mainView: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    currentPage: 0,
    totalPages: 0,
    loading: false,
    selected: [],
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "index"
      },
      {
        text: "Номер карты",
        align: "start",
        value: "cardNo"
      },
      {text: "ФИО", value: "person4List.fullName"},
      {text: "ИИН", value: "person4List.iin", conditionalRendering: true},
      {text: "Прикреплен к", value: "parent.fullName"},
      {text: "Страховая", value: "underwriter.fullName"},
      {text: "Компания", value: "organization.fullName"},
      {
        text: "Инд. № договора",
        value: "individualContractNo"
      },
      {text: "С", value: "startDate"},
      {text: "По", value: "endDate"},
      {text: "Программа", value: "program.name"},
      {text: "Премия", sortable: false, value: "premium"},
      {text: "Сумма", sortable: false, value: "amount"},
      {text: "Город", sortable: true, value: "cityName"},
    ],
    items: [],
    itemsFull: [],
    new_: undefined,
    searchParams: undefined,
    isExpanded: true,
    expanded: [],
    render: function (createElement) {
      return createElement(
        "h" + this.level, // имя тега
        this.$slots.default // массив дочерних элементов
      );
    }
  }),

  async mounted() {
    this.$parent.insuredList = this;

    document.getElementById("listWrapper").addEventListener("scroll", this.handleScroll);

    if (!this.contractId){
      this.fetchItems({page: 0});
    }
    else {
      this.fetchByContract({page: 0});
    }

    this.headers = this.headers.filter(header => !header.conditionalRendering || (header.conditionalRendering && !this.mainView));
    // Example: Triggering row click for the first row when the component is mounted
    // this.programmaticRowClick(0);
  },

  created() {
    this.$root.$refs.InsuredList = this;
  },

  watch: {
    selected: function (val) {

      if (val.length === 0) {
        return (this.$parent.item = this.new_ ? this.new_ : undefined);
      } else {
        this.new_ = undefined;
        let item = new Item(vm2js(val[0]));
        item.person = vm2js(item.person4List);
        this.$parent.item = item;
      }
    },
    contractId: function (val) {
      if (!val) return;
      this.items = [];
      this.fetchByContract({page: 0});
    },
    "vm.showAll": function(val) {
      if(this.mainView && !val) this.filterItemsByEndDate()
      else this.items = this.itemsFull
    }
  },
  methods: {

    filterItemsByEndDate() {
      this.itemsFull = this.items
      this.items = this.items.filter(i => moment(new Date()).diff(moment(i.endDate), 'years', true) <= 3);

    },
    itemColor(item) {
      if (item.cardStatus === insuredCardStatusEnum.NOT_STARTED) return "item-blue";
      else if (item.cardStatus === insuredCardStatusEnum.EXPIRED) return "item-orange";
      else if (item.cardStatus === insuredCardStatusEnum.VMS) return "item-green"
      return "";
    },
    
    add() {
      this.new_ = {person: {}};
      this.selected = [];
    },

    close() {
      this.new_ = undefined;
      this.selected = [];
    },

    async save() {

      const res = await fetch.post(
        "/api/insurance/insured/save",
        vm2js(this.vm.item)
      );

      if (res) {
        ctx.src.persons.assign(this.vm.item.person).then(() => {
          if (this.vm && this.vm.item.id) {

            let found = this.items.find(i => {
              return i.id === res.id;
            });

            if (found) {
              found.init(res, true);
            }

            this.items.forEach(i => {
              if (i.parentId === res.id) {
                i.startDate = dateIsoFilter(res.startDate);
                i.endDate = dateIsoFilter(res.endDate);
                i.amount = res.amount;
              }
            });

          }
          else {
            this.vm.item.id = res;
            this.items.unshift(new Item(res));
          }

          if (this.$parent.refreshDetail) {
            this.$parent.refreshDetail();
          }

        });
        await ctx.src.insured.pull(res.id);
      }
    },

    async rem() {
      const res = await fetch.post(
        "/api/insurance/insured/delete?id=" + this.$parent.item.id
      );

      if (res) {
        this.items.splice(this.items.indexOf(this.selected[0]), 1);
        this.close();

        this.$parent.dialogDelete = false;
      }
    },

    search(searchText, underwriterId, includeIin) {
      this.items = [];
      this.selected = [];
      this.currentPage = 0;
      if (searchText)
        this.searchParams = {
          predicate: searchText,
          underwriterId,
          includeIin: includeIin,
        };
      else this.searchParams = undefined;
      const params = {
        page: this.currentPage
      };
      this.fetchItems(params, this.searchParams);
    },

    async searchByServiceCheckNumber(searchText) {
      this.loading = true;
      this.items = [];
      this.selected = [];
      const res = await fetch.get(
        "/api/insurance/insured/search-by-service-check-number?predicate=" +
        searchText
      );
      res.forEach(element => {
        this.items.push(new Item(element));
      });
      this.loading = false;
    },

    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          if (!this.contractId) this.fetchItems(params, this.searchParams);
          else this.fetchByContract(params);
        }
      }
    },

    rowClick(obj, row) {
      if (!row?.isSelected) {
        row.select("OBJ", obj);
      } else {
        this.selected = [];
      }
    },

    // Method to programmatically trigger row click
    // programmaticRowClick(index) {
    //   const dataTable = this.$refs.dataTable;
    //   const rows = dataTable.$refs.body.rows;

    //   if (index >= 0 && index < rows.length) {
    //     const row = rows[index];
    //     const item = this.vm; // Assuming your items array corresponds to the rows

    //     row.$emit('click', { item, index }); // Simulate row click
    //   }
    // },    

    
    async fetchByContract() {
      this.loading = true;
      let res = await fetch.get(
        "/api/insurance/insured/get-by-contract?contractId=" + this.contractId
      );
      res.forEach(element => {
        this.items.push(new Item(element));
      });
      this.loading = false;
    },
  

    async fetchItems(params, searchParams) {
      this.loading = true;
      let res;
      if (!searchParams)
        res = await fetch.get(
          "/api/insurance/insured/get-list?page=" + params.page
        );
      else
        res = await fetch.get(
          "/api/insurance/insured/search?predicate=" +
          searchParams.predicate +
          (searchParams.underwriterId
            ? "&underwriterId=" + searchParams.underwriterId
            : "&underwriterId=") +
          "&page=" +
          params.page +
          "&includeIin=" +
          (!!searchParams.includeIin)
        );

      if (res.first) {
        this.items = [];
        this.totalPages = res.totalPages;
      }
      res.content.forEach(element => {
        this.items.push(new Item(element));
      });
      if(this.mainView && !this.vm.showAll) this.filterItemsByEndDate()
      this.loading = false;
    },
    async exp() {
      this.loading = true;
      this.items.forEach(i => {
        i.fullName = i.person4List.fullName;
        i.person = {
          "iin": i.person4List.iin
        }
      });
      const res = await fetch.post(
        "/api/insurance/insured/export?contractId=" + this.contractId,
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.loading = false;
    },
    init() {
      this.currentPage = 0;
      this.totalPages = 0;
      this.selected = [];
      this.items = [];
      this.fetchItems({page: 0});
    }
  },
};
</script>
<style lang="scss">
.view-wrapper-contracts .v-window-item:nth-child(3) .v-data-table__wrapper,
.view-wrapper-insured .v-data-table__wrapper {
  > table {
    counter-reset: section;

    tbody tr td:nth-child(2)::before {
      counter-increment: section;
      content: counter(section);
    }
  }
}
</style>

<style scoped>
  #listWrapper {
    overflow-y: scroll;
  }
</style>
